
import { computed, defineComponent, ref } from "vue";
import { ItemView } from "@/assets/ts/itemView";
import IconItemColor from "@/components/icon/iconItemColor.vue";

export default defineComponent({
  components: { IconItemColor },
  props: {
    itemObj: ItemView,
    pageStr: String,
    loadingSessions: Number,
  },
  setup(props) {
    /**
     *
     */

    const pluginHost = process.env.VUE_APP_PLUGIN_HOST || "";

    const editParameter = ref("");

    const possibleVariations = computed(() => {
      const variations: {
        idx: string;
        desc: string;
        cta: string;
        title: string;
        link: string;
        list: {
          match: boolean;
          slug: string;
          desc: string;
          price: number;
        }[];
      }[] = [];
      if (props.itemObj) {
        if (props.itemObj.getMetals().length > 1) {
          variations.push({
            idx: "metals",
            desc: props.itemObj.getMetals().filter((x) => x.match)[0].desc,
            cta: ".metals.cta",
            title: ".metals.title",
            link: ".metals.alloyLink",
            list: props.itemObj.getMetals(),
          });
        }

        if (props.itemObj.getJewellerySizes().length > 1) {
          variations.push({
            idx: "sizes",
            desc: props.itemObj.getJewellerySizes().filter((x) => x.match)[0]
              .desc,
            cta: ".sizes.cta",
            title: ".sizes.title",
            link: ".sizes.diamondLink",
            list: props.itemObj.getJewellerySizes(),
          });
        }

        if (props.itemObj.getJewelleryQualities().length > 1) {
          variations.push({
            idx: "quality",
            desc: props.itemObj
              .getJewelleryQualities()
              .filter((x: { match: boolean }) => x.match)[0].desc,
            cta: ".quality.cta",
            title: ".quality.title",
            link: ".quality.diamondLink",
            list: props.itemObj.getJewelleryQualities(),
          });
        }

        if (props.itemObj.getJewelleryStoneNumbers().length > 1) {
          variations.push({
            idx: "numbers",
            desc: props.itemObj
              .getJewelleryStoneNumbers()
              .filter((x: { match: boolean }) => x.match)[0].desc,
            cta: ".number.cta",
            title: ".number.title",
            link: "",
            list: props.itemObj.getJewelleryStoneNumbers(),
          });
        }

        if (props.itemObj.getJewelleryFinishes().length > 1) {
          variations.push({
            idx: "finishes",
            desc: props.itemObj.getJewelleryFinishes().filter((x) => x.match)[0]
              .desc,
            cta: ".finish.cta",
            title: ".finish.title",
            link: "",
            list: props.itemObj.getJewelleryFinishes(),
          });
        }

        if (props.itemObj.getJewelleryModels().length > 1) {
          variations.push({
            idx: "models",
            desc: props.itemObj.getJewelleryModels().filter((x) => x.match)[0]
              .desc,
            cta: ".model.cta",
            title: ".model.title",
            link: "",
            list: props.itemObj.getJewelleryModels(),
          });
        }
      }

      return variations;
    });

    const getPossibleIconStoneSize = (stoneSizeStr: string) => {
      const possible: string[] = [
        "010",
        "015",
        "020",
        "025",
        "030",
        "040",
        "050",
        "070",
        "100",
        "150",
        "200",
      ];
      const finalStoneSite = stoneSizeStr
        .replace(".", "")
        .replace(",", "")
        .substring(0, 3);

      if (possible.includes(finalStoneSite)) {
        return finalStoneSite;
      } else {
        let lastStoneSize = possible[0];
        for (const idx in possible) {
          if (possible[idx] > finalStoneSite) {
            break;
          } else {
            lastStoneSize = possible[idx];
          }
        }

        return lastStoneSize;
      }
    };

    const getPossibleIconStoneQuality = (stoneQuality: string) => {
      return "white";
    };

    return {
      pluginHost,
      editParameter,
      possibleVariations,
      getPossibleIconStoneSize,
      getPossibleIconStoneQuality,
    };
  },
});
