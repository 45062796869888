
import {defineComponent, ref, computed, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

import {Sets} from "@/assets/ts/dao/sets";
import {Items} from "@/assets/ts/dao/items";
import {Conf} from "@/assets/ts/dao/conf";
import {ItemView} from "@/assets/ts/itemView";
import {domainByCountry} from "@/assets/ts/Domains";
import ItemConfiguration from "@/components/item/itemConfiguration.vue";
import ElementButton from "@/components/element/elementButton.vue";

declare const window: any;

export default defineComponent({
  head: {},
  components: {
    ElementButton,
    ItemConfiguration,
  },
  setup() {
    const pluginHost = process.env.VUE_APP_PLUGIN_HOST || "";

    const route = useRoute();

    const pageStr = "item";

    const store = useStore();
    const country = "de";
    const language = "de";

    const editParameter = ref("");
    const setSeoLink = ref("" as string);

    const video3dSetId = ref("" as string);
    const curImage = ref("main" as string);
    const loadingSessions = ref(0);

    /**
     * configurator link
     */

    const configuratorLink = ref(false)

    if (typeof window.acredoCatalog !== "undefined") {
      configuratorLink.value = window.acredoCatalog.itemView.configuratorLink;
    }

    /**
     * item
     */
    const getDefaultItem = () => {
      return new ItemView({
        jewellery_type: "0",
        finegold: [],
        price: "0",
        itemnumber: "",
        fineness: "",
        height: "0",
        stone_quality: "0",
        picture_600: pluginHost + "/img/item/def_item.jpg",
        description: {
          global_stone: {
            quality_short: "",
            quality: "",
            text: "",
          },
          text_alloy: "",
          text_profile: "",
          stone_size: "",
          stone_quality: "",
        },
        colorVars: [],
        jewelleryVars: {
          sizes: [],
          qualities: [],
          numbers: [],
          finishes: [],
          models: [],
          settings: [],
        },
        set_itemnumber1: "",
        set_itemnumber2: "",
        set_itemnumber3: "",
        item1_price: "",
        item2_price: "",
        item3_price: "",
      });
    };

    const item = ref({
      slug: "",
      obj: getDefaultItem(),
    });

    const loadItem = (uri: string) => {
      store.commit("loading/actionStarted");

      item.value = {
        slug: "",
        obj: getDefaultItem(),
      };

      if (uri.match(/^[A-Za-z]{1,2}-[0-9]{4}-[0-9]{1,}/)) {
        new Sets().get(country, language, uri).then((data) => {
          item.value.slug = route.params.set as string;
          item.value.obj = new ItemView(data as any);

          store.commit("loading/actionFinished", 2);
        });
      } else {
        const confObj: Conf = new Conf();

        new Items().get(country, language, uri).then((data) => {
          item.value.slug = route.params.set as string;
          item.value.obj = new ItemView(data as any);

          store.commit("loading/actionFinished", 2);

          /**
           * Conf
           */

          loadingSessions.value++;

          confObj
              .getJewelleryNormalStones(country, language, uri)
              .then((normalStoneData: any) => {
                loadingSessions.value--;
                item.value.obj.item.jewelleryVars.sizes = normalStoneData.sizes;
                item.value.obj.item.jewelleryVars.qualities =
                    normalStoneData.qualities;
                item.value.obj.item.jewelleryVars.numbers =
                    normalStoneData.numbers;
              })
              .catch(() => {
                loadingSessions.value--;
              });

          loadingSessions.value++;

          confObj
              .getFinishes(country, language, uri)
              .then((finishData: any) => {
                loadingSessions.value--;
                item.value.obj.item.jewelleryVars.finishes = finishData;
              })
              .catch(() => {
                loadingSessions.value--;
              });

          loadingSessions.value++;

          confObj
              .getModels(country, language, uri)
              .then((modelData: any) => {
                loadingSessions.value--;
                item.value.obj.item.jewelleryVars.models = modelData;
              })
              .catch(() => {
                loadingSessions.value--;
              });

          loadingSessions.value++;

          confObj
              .getSettings(country, language, uri)
              .then((data: any) => {
                loadingSessions.value--;
                item.value.obj.item.jewelleryVars.settings = data;
              })
              .catch(() => {
                loadingSessions.value--;
              });
        });
      }
    };

    /**
     * URL change
     */

    watch(
        () => route.params.set,
        (newStr, oldValue) => {
          loadItem(newStr.toString());
        }
    );

    if (typeof route === "object") {
      loadItem(route.params.set.toString());
    }

    const linkConfigurator = computed((): string => {
      return domainByCountry(country, true);
    });

    const linkAppointment = computed(() => {
      if (typeof window.acredoCatalog !== "undefined") {
        return typeof window.acredoCatalog.itemView.appointmentLink === "string"
            ? window.acredoCatalog.itemView.appointmentLink
            : "";
      } else {
        return "";
      }
    });

    return {
      pageStr,
      item,
      editParameter,
      linkConfigurator,
      setSeoLink,
      video3dSetId,
      curImage,
      country,
      loadingSessions,
      linkAppointment,
      pluginHost,
      configuratorLink,
    };
  },
});
