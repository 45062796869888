export class Conf {
  private host: string = process.env.VUE_APP_API_HOST || "";

  getFinishes(country: string, language: string, itemNumber: string) {
    return new Promise((resolve, reject) => {
      const url =
        this.host +
        "/item/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/" +
        itemNumber.toUpperCase() +
        "/conf/finishes";

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const result: {
            itemNumber: string[];
            text: string;
            match: boolean;
            color: string;
            finish: string;
          }[] = [];

          for (const finishIdx in data[0]) {
            result.push({
              itemNumber: data[0][finishIdx].itemnumber[0],
              text: data[0][finishIdx].text,
              match: data[0][finishIdx].match,
              color: data[0][finishIdx].color,
              finish: data[0][finishIdx].finsih,
            });
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  }

  getModels(country: string, language: string, itemNumber: string) {
    return new Promise((resolve, reject) => {
      const url =
        this.host +
        "/item/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/" +
        itemNumber.toUpperCase() +
        "/conf/model";

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const result: {
            itemNumber: string[];
            text: string;
            match: boolean;
          }[] = [];

          for (const idx in data) {
            result.push({
              itemNumber: data[idx].itemnumber[0],
              text: data[idx].text,
              match: data[idx].match,
            });
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  }

  getSettings(country: string, language: string, itemNumber: string) {
    return new Promise((resolve, reject) => {
      const url =
        this.host +
        "/item/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/" +
        itemNumber.toUpperCase() +
        "/conf/setting";

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const result: {
            itemNumber: string[];
            text: string;
            match: boolean;
          }[] = [];

          for (const idx in data) {
            result.push({
              itemNumber: data[idx].itemnumber[0],
              text: data[idx].text,
              match: data[idx].match,
            });
          }
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  }

  getJewelleryNormalStones(
    country: string,
    language: string,
    itemNumber: string
  ) {
    return new Promise((resolve, reject) => {
      const url =
        this.host +
        "/item/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/" +
        itemNumber.toUpperCase() +
        "/conf/jewellery_normal";

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({
            sizes: data.sizes.map(
              (x: { itemnumber: string[]; text: string; match: boolean }) => {
                return {
                  itemNumber: x.itemnumber[0],
                  text: x.text,
                  match: x.match,
                };
              }
            ),
            qualities: data.qualities.map(
              (x: {
                itemnumber: string[];
                textShort: string;
                match: boolean;
              }) => {
                return {
                  itemNumber: x.itemnumber[0],
                  text: x.textShort,
                  match: x.match,
                };
              }
            ),
            numbers: data.numbers.map(
              (x: { itemnumber: string[]; text: string; match: boolean }) => {
                return {
                  itemNumber: x.itemnumber[0],
                  text: x.text,
                  match: x.match,
                };
              }
            ),
            pools: [],
          });
        })
        .catch((error) => reject(error));
    });
  }

  getJewelleryModelStones(
    country: string,
    language: string,
    itemNumber: string
  ) {
    return new Promise((resolve, reject) => {
      const url =
        this.host +
        "/item/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/" +
        itemNumber.toUpperCase() +
        "/conf/jewellery_model";

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({
            qualities: data.qualities.map(
              (x: { itemnumber: string[]; text: string; match: boolean }) => {
                return {
                  itemNumber: x.itemnumber[0],
                  text: x.text,
                  match: x.match,
                };
              }
            ),
            variations: data.variations.map(
              (x: {
                itemnumber: string[];
                textShort: string;
                match: boolean;
              }) => {
                return {
                  itemNumber: x.itemnumber[0],
                  text: x.textShort,
                  match: x.match,
                };
              }
            ),
          });
        })
        .catch((error) => reject(error));
    });
  }
}
