import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "acredo-catalog",
  class: "relative bg-white w-full py-8"
}
const _hoisted_2 = {
  key: 0,
  class: "max-w-screen-xl mx-auto"
}
const _hoisted_3 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_catalog_nav = _resolveComponent("catalog-nav")!
  const _component_catalog_set = _resolveComponent("catalog-set")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.catalogSets.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_catalog_nav, {
            "current-page": $setup.currentPage,
            "number-of-sets": $setup.numberOfSets,
            "items-per-page": $setup.itemsPerPage,
            "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ($setup.currentPage = $event))
          }, null, 8, ["current-page", "number-of-sets", "items-per-page"]),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.catalogSets, (set) => {
              return (_openBlock(), _createElementBlock("li", {
                key: set.colors[0].setId,
                class: "p-4"
              }, [
                _createVNode(_component_catalog_set, { set: set }, null, 8, ["set"])
              ]))
            }), 128))
          ]),
          _createVNode(_component_catalog_nav, {
            "current-page": $setup.currentPage,
            "number-of-sets": $setup.numberOfSets,
            "items-per-page": $setup.itemsPerPage,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ($setup.currentPage = $event))
          }, null, 8, ["current-page", "number-of-sets", "items-per-page"])
        ]))
      : _createCommentVNode("", true)
  ]))
}