export class DaoCatalog {
  get(country: string, catalogSlug: string) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_S3_HOST + "/catalogs/" + country + "/" + catalogSlug + ".json", {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 500) {
            reject(new Error("Unknown catalog"));
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(() => {
          reject(new Error("Unknown catalog"));
        });
    });
  }
}
