
/** Nutzung:
 * Mit URL Link
 * <element-button :link="{ Linkziel }">{ Text }</html-brand-button>
 *
 * <element-button :link="localePath('shops')">Standorte</html-brand-button>
 * <element-button :link="https://www.acredo-trauringe.de">Acredo Website</html-brand-button>
 *
 * Mit Klick-Event
 * <element-button @click.native="{ Funktion }">{ Text }</html-brand-button>
 */

import { defineComponent } from "vue";

export default defineComponent({
  props: ["link", "inverseStyle"]
});
