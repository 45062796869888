export class Items {
  get(country: string, language: string, itemNumber: string) {
    return new Promise((resolve, reject) => {
      let url = process.env.VUE_APP_API_HOST + "/item";
      url += "/" + country.toLowerCase();
      url += "/" + language.toLowerCase();
      url += "/" + itemNumber.toUpperCase();

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
