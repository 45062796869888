
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: ["colors"],
  setup(props: { colors: string[] }) {
    const colorList = computed(() => {
      if (props.colors) {
        const reverseColors = props.colors
        return reverseColors.reverse();
      } else {
        return [];
      }
    });

    const rgbColors: { [key: string]: string[] } = {
      B: ["#fffaee", "#f7e5ac", "#ccbf8b"],
      G: ["#fffaf0", "#f9e1b1", "#cdbb8f"],
      E: ["#fff7ef", "#f8d5af", "#cdb08f"],
      R: ["#fff3ef", "#f8c8b0", "#cda58f"],
      X: ["#fdf7f2", "#f3dbc2", "#cfbba5"],
      W: ["#f9f9f9", "#ebebeb", "#bdbdbd"],
      D: ["#f2f2ef", "#dfdfdc", "#a0a09e"],
      P: ["#e2e2e3", "#cccccf", "#96969c"],
      S: ["#f2f2ec", "#e6e6e1", "#b3b3af"],
      C: ["#b3b3b3", "#808080", "#333333"],
    };

    return { rgbColors, colorList };
  },
});
