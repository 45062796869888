type itemStructure = {
  itemnumber: string;
  // eslint-disable-next-line camelcase
  picture_600: string;
  // eslint-disable-next-line camelcase
  jewellery_type: string;
  colorVars: {
    itemnumber: string;
    price: string;
    // eslint-disable-next-line camelcase
    picture_600: string;
    // eslint-disable-next-line camelcase
    alloy_key: string;
  }[];
  fineness: string;
  finegold: {
    itemnumber: string;
    price: string;
    text: string;
  }[];
  height: string;
  price: string;
  // eslint-disable-next-line camelcase
  stone_quality: string;
  description: {
    // eslint-disable-next-line camelcase
    global_stone: {
      // eslint-disable-next-line camelcase
      quality_short: string;
      quality: string;
      text: string;
    };
    // eslint-disable-next-line camelcase
    text_alloy: string;
    // eslint-disable-next-line camelcase
    text_profile: string;
    // eslint-disable-next-line camelcase
    stone_size: string;
    // eslint-disable-next-line camelcase
    stone_quality: string;
  };
  // eslint-disable-next-line camelcase
  set_itemnumber1: string;
  // eslint-disable-next-line camelcase
  set_itemnumber2: string;
  // eslint-disable-next-line camelcase
  set_itemnumber3: string;
  // eslint-disable-next-line camelcase
  item1_price: string;
  // eslint-disable-next-line camelcase
  item2_price: string;
  // eslint-disable-next-line camelcase
  item3_price: string;
  jewelleryVars: { [Key: string]: any };
};

type varsListStructur = {
  set: string;
  fineness: string;
  height: string;
  quality: string;
  price: number;
  image: string;
  colors: string[];
  slug: string;
  desc: string;
  match: boolean;
};

export class ItemView {
  item: itemStructure;

  constructor(itemData: itemStructure) {
    this.item = itemData;
    this.item.jewelleryVars = {
      sizes: [],
      qualities: [],
      numbers: [],
      finishes: [],
      models: [],
      settings: [],
    };
  }

  getItemnumber(): string {
    return this.item.itemnumber ? this.item.itemnumber.toUpperCase() : "";
  }

  getSlug(): string {
    return (
      this.item.itemnumber +
      "|" +
      this.item.fineness +
      "|" +
      this.item.height +
      "|" +
      this.item.stone_quality
    ).toUpperCase();
  }

  getColorSlug(): string {
    return this.item.fineness.substring(0, this.item.fineness.length - 1);
  }

  getPrice(): number {
    return (
      this.getItemPrices(1) + this.getItemPrices(2) + this.getItemPrices(3)
    );
  }

  getItemPrices(itemIdx: number): number {
    type ObjectKey = keyof itemStructure;

    const itemPriceIdx: ObjectKey = ("item" +
      itemIdx.toString() +
      "_price") as ObjectKey;

    return itemIdx > 0 && itemIdx < 4
      ? parseFloat(<string>this.item[itemPriceIdx as ObjectKey])
      : 0;
  }

  getNumberOfItems(): number {
    if (this.item.set_itemnumber3 !== "") {
      return 3;
    } else if (this.item.set_itemnumber2 !== "") {
      return 2;
    } else {
      return 1;
    }
  }

  getJewelleryType(): string {
    const itemTypes: { [key: string]: string } = {
      "0": "weddingRing",
      "1": "earing",
      "2": "memoire",
      "3": "engagementRing",
      "4": "collier",
      "5": "diamond",
      "6": "ring",
      "7": "bracelet",
      "11": "chain",
      "21": "bracelet",
    };
    return itemTypes[this.item.jewellery_type.toString()] || "0";
  }

  getImage(): string {
    return this.item.picture_600.replace("/sets/", "/acredo/sets/");
  }

  getDescription(limit = "all"):
    | string
    | {
        quality_short: string;
        quality: string;
        text: string;
      }
    | {
        global_stone: {
          quality_short: string;
          quality: string;
          text: string;
        };
        text_alloy: string;
        text_profile: string;
        stone_size: string;
        stone_quality: string;
      } {
    if (limit === "metal") {
      return this.item.description.text_alloy;
    } else if (limit === "profile") {
      return this.item.description.text_profile;
    } else if (limit === "stone_size") {
      return this.item.description.stone_size;
    } else if (limit === "stone_quality") {
      return this.item.description.stone_quality;
    } else if (limit === "stone") {
      if (typeof this.item.description.global_stone === "object") {
        return {
          quality_short:
            this.item.description.global_stone.quality_short.replace(
              /&amp;/gi,
              "&"
            ),
          quality: this.item.description.global_stone.quality.replace(
            /&amp;/gi,
            "&"
          ),
          text: this.item.description.global_stone.text.replace(/&amp;/gi, "&"),
        };
      } else {
        return "";
      }
    } else {
      return this.item.description;
    }
  }

  getMetals(): varsListStructur[] {
    const metals: varsListStructur[] = [];

    for (const metalIdx in this.item.finegold) {
      const metal = this.item.finegold[metalIdx];

      const finenessStr = metal.itemnumber.split("-")[2];

      let itemSlug = metal.itemnumber.toUpperCase();

      if (this.item.itemnumber.match(/^[A-Za-z]{1,2}-[0-9]{4}-[0-9]+/)) {
        itemSlug = (
          this.item.itemnumber +
          "_" +
          finenessStr +
          "_" +
          this.item.height +
          "_" +
          this.item.stone_quality
        ).toLowerCase();
      }

      const metalVar: varsListStructur = {
        set: this.item.itemnumber,
        fineness: finenessStr,
        height: this.item.height,
        quality: this.item.stone_quality,
        price: this.parsePrice(metal.price),
        image: this.item.picture_600,
        colors: this.parseColorList(finenessStr),
        slug: itemSlug,
        desc: metal.text,
        match: finenessStr === this.item.fineness,
      };

      metals.push(metalVar);
    }

    return metals;
  }

  getColors(): varsListStructur[] {
    const colors: varsListStructur[] = [];

    for (const colorIdx in this.item.colorVars) {
      const color = this.item.colorVars[colorIdx];

      if (color.alloy_key == null) {
        continue;
      }

      const colorsList: string[] = this.parseColorList(color.alloy_key);

      if (
        colorsList.length > 2 ||
        (colorsList.length === 2 &&
          !colorsList.includes("W") &&
          !colorsList.includes("D"))
      ) {
        continue;
      }

      let itemSlug = color.itemnumber;
      if (this.item.itemnumber.match(/^[A-Za-z]{1,2}-[0-9]{4}-[0-9]+/)) {
        itemSlug = (
          color.itemnumber +
          "_" +
          color.alloy_key +
          "_" +
          this.item.height +
          "_" +
          this.item.stone_quality
        ).toLowerCase();
      }

      const colorVar: varsListStructur = {
        set: color.itemnumber,
        fineness: color.alloy_key,
        height: this.item.height,
        quality: this.item.stone_quality,
        price: this.parsePrice(color.price),
        image: color.picture_600,
        colors: colorsList,
        slug: itemSlug,
        desc: "",
        match: color.alloy_key === this.item.fineness,
      };

      colors.push(colorVar);
    }

    colors.sort((a, b) => (a.price < b.price ? -1 : 1));

    return colors;
  }

  getJewellerySizes(): varsListStructur[] {
    const sizes: varsListStructur[] = [];

    if (typeof this.item.jewelleryVars !== "undefined") {
      for (const sizeIdx in this.item.jewelleryVars.sizes) {
        const size = this.item.jewelleryVars.sizes[sizeIdx];

        const sizeVar: varsListStructur = {
          set: size.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: 0,
          image: "",
          colors: [],
          slug: size.itemNumber,
          desc: size.text,
          match: size.match,
        };

        sizes.push(sizeVar);
      }

      sizes.sort((a, b) => (a.desc < b.desc ? -1 : 1));
    }
    return sizes;
  }

  getJewelleryQualities(): varsListStructur[] {
    const qualities: varsListStructur[] = [];

    if (typeof this.item.jewelleryVars !== "undefined") {
      for (const qualityIdx in this.item.jewelleryVars.qualities) {
        const quality = this.item.jewelleryVars.qualities[qualityIdx];

        const qualityVar: varsListStructur = {
          set: quality.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: quality.price,
          image: "",
          colors: [],
          slug: quality.itemNumber,
          desc: quality.text,
          match: quality.match,
        };

        qualities.push(qualityVar);
      }

      qualities.sort((a, b) => (a.price < b.price ? -1 : 1));
    }
    return qualities;
  }

  getJewelleryStoneNumbers(): varsListStructur[] {
    const numbers: varsListStructur[] = [];

    if (typeof this.item.jewelleryVars !== "undefined") {
      for (const numberIdx in this.item.jewelleryVars.numbers) {
        const number = this.item.jewelleryVars.numbers[numberIdx];

        const qualityVar: varsListStructur = {
          set: number.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: 0,
          image: "",
          colors: [],
          slug: number.itemNumber,
          desc: number.text,
          match: number.match,
        };

        numbers.push(qualityVar);
      }

      numbers.sort((a, b) => (a.desc < b.desc ? -1 : 1));
    }
    return numbers;
  }

  getJewelleryFinishes(): varsListStructur[] {
    const finishes: varsListStructur[] = [];

    if (typeof this.item.jewelleryVars !== "undefined") {
      for (const finishIdx in this.item.jewelleryVars.finishes) {
        const finish = this.item.jewelleryVars.finishes[finishIdx];

        const qualityVar: varsListStructur = {
          set: finish.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: 0,
          image: "",
          colors: [],
          slug: finish.itemNumber,
          desc: finish.text,
          match: finish.match,
        };

        finishes.push(qualityVar);
      }

      finishes.sort((a, b) => (a.desc < b.desc ? -1 : 1));
    }
    return finishes;
  }

  getJewelleryModels(): varsListStructur[] {
    const models: varsListStructur[] = [];

    if (typeof this.item.jewelleryVars !== "undefined") {
      for (const modelIdx in this.item.jewelleryVars.models) {
        const model = this.item.jewelleryVars.models[modelIdx];

        const item = {
          set: model.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: 0,
          image: "",
          colors: [],
          slug: model.itemNumber,
          desc: "", // model.text,
          match: model.match,
        };

        if (models.find((el) => el.slug === item.slug) === undefined) {
          models.push(item);
        }
      }

      if (typeof this.item.jewelleryVars.settings !== "undefined") {
        for (const idx in this.item.jewelleryVars.settings) {
          const setting = this.item.jewelleryVars.settings[idx];

          const item = {
            set: setting.itemNumber,
            fineness: "",
            height: "",
            quality: "",
            price: 0,
            image: "",
            colors: [],
            slug: setting.itemNumber,
            desc: "", // setting.text,
            match: setting.match,
          };

          if (models.find((el) => el.slug === item.slug) === undefined) {
            models.push(item);
          }
        }
      }

      models.sort((a, b) => (a.desc < b.desc ? -1 : 1));
    }
    return models;
  }

  getVariations(varType: string): varsListStructur[] {
    const varList: varsListStructur[] = [];

    if (
      typeof this.item.jewelleryVars !== "undefined" &&
      this.item.jewelleryVars[varType] !== "undefined"
    ) {
      for (const idx in this.item.jewelleryVars[varType]) {
        const varItem = this.item.jewelleryVars[varType][idx];

        varList.push({
          set: varItem.itemNumber,
          fineness: "",
          height: "",
          quality: "",
          price: 0,
          image: "",
          colors: [],
          slug: varItem.itemNumber,
          desc: varItem.text,
          match: varItem.match,
        });
      }

      varList.sort((a, b) => (a.desc < b.desc ? -1 : 1));
    }
    return varList;
  }

  parsePrice(rawPrice: string): number {
    if (
      rawPrice.substring(rawPrice.length - 2, rawPrice.length) === ",-" ||
      rawPrice.substring(rawPrice.length - 2, rawPrice.length) === ",00"
    ) {
      rawPrice = rawPrice.replace(",-", "");
      rawPrice = rawPrice.replace(",00", "");
      rawPrice = rawPrice.replace(".", "");
      rawPrice = rawPrice.replace(",", "");
    } else if (
      rawPrice.substring(rawPrice.length - 2, rawPrice.length) === ".-" ||
      rawPrice.substring(rawPrice.length - 2, rawPrice.length) === ".00" ||
      rawPrice.substring(rawPrice.length - 4, rawPrice.length - 3) === ","
    ) {
      rawPrice = rawPrice.replace(".-", "");
      rawPrice = rawPrice.replace(".00", "");
      rawPrice = rawPrice.replace(".", "");
      rawPrice = rawPrice.replace(",", "");
    }

    return parseFloat(rawPrice);
  }

  parseColorList(fineness: string = this.item.fineness): string[] {
    return Array.from(new Set(fineness.substr(0, fineness.length - 1)));
  }
}
