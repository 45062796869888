
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "CatalogNav",
  components: {},
  props: ["currentPage", "numberOfSets", "itemsPerPage"],
  setup(
    props: {
      currentPage: number;
      numberOfSets: number;
      itemsPerPage: number;
    },
    { emit }
  ) {
    /**
     * Pagination
     */

    const maxNumberOfPages = computed(() => {
      return Math.ceil(props.numberOfSets / props.itemsPerPage);
    });

    const lastPage = () => {
      emit(
        "update:currentPage",
        props.currentPage - 1 < 1 ? 0 : props.currentPage - 1
      );
    };

    const nextPage = () => {
      emit(
        "update:currentPage",
        props.currentPage + 1 > maxNumberOfPages.value
          ? maxNumberOfPages.value
          : props.currentPage + 1
      );
    };

    const selectPage = (newPage: number) => {
      if (newPage >= 0 && newPage <= maxNumberOfPages.value) {
        emit("update:currentPage", newPage);
      }
    };

    return {
      lastPage,
      nextPage,
      selectPage,
      maxNumberOfPages,
    };
  },
});
