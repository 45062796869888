export class Sets {
  get(country: string, language: string, setId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let alloy = "";
      let height = "";
      let quality = "";

      if (setId.includes("_")) {
        const setIdParts = setId.split("_");
        setId = setIdParts[0];

        alloy = setIdParts[1];

        if (setIdParts.length > 2) {
          height = setIdParts[2];
        }

        if (setIdParts.length > 3) {
          quality = setIdParts[3];
        }
      }

      let url = process.env.VUE_APP_API_HOST + "/set";
      url += "/" + country.toLowerCase();
      url += "/" + language.toLowerCase();

      url += "/" + setId.toUpperCase();
      url += "/" + alloy.toUpperCase();
      url += "/" + height.toUpperCase();
      url += "/" + quality.toUpperCase();

      if (url.substring(url.length - 1, url.length) === "/") {
        url += "0";
      }

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
