<template>
  <div v-if="currentSet && currentSet.setId">
    <router-link
      :to="{
        name: 'Item',
        params: {
          set: link,
        },
      }"
    >
      <img
        :src="
          'https://images.acredo.com/sets/250/' +
          currentSet.setId.toLowerCase() +
          '_250.jpg'
        "
        :alt="currentSet.setId + ' ' + currentSet.metal"
        class="mx-auto"
      />
    </router-link>
    <div
      class="my-4 text-sm font-extralight text-center mx-auto lg:w-60 px-2 lg:px-0"
    >
      <span
        class="inline-block w-4/12 text-left"
        :class="{ 'text-brand-promo-bg': currentSet.promotion }"
      >
        {{ $t("catalog.price.goldFrom") }}
      </span>
      <span
        class="inline-block w-4/12 text-right"
        :class="{ 'text-brand-promo-bg': currentSet.promotion }"
      >
        {{ $n(set.price.gold, "currency") }}
      </span>
      <br />
      <div v-if="set.price.platinum">
        <span class="inline-block w-4/12 text-left">
          {{ $t("catalog.price.platinumFrom") }}
        </span>
        <span class="inline-block w-4/12 text-right">
          {{ $n(set.price.platinum, "currency") }}
        </span>
      </div>
      <div v-else>
        <span class="inline-block w-4/12 text-left"> &nbsp; </span>
        <span class="inline-block w-4/12 text-right"> &nbsp; </span>
      </div>
    </div>
    <div class="px-8 flex">
      <ul class="colors flex space-x-2 mx-auto">
        <template v-for="(color, colorIdx) in set.colors">
          <li
            :key="colorIdx"
            v-if="
              ['w', 'g', 'x', 'e'].includes(color.metal.charAt(0).toLowerCase())
            "
            class="cursor-pointer"
          >
            <img
              class="w-6"
              :class="{
                'border border-gray-800 rounded-full': colorIdx === leader,
              }"
              :alt="color.metal.charAt(0).toLowerCase()"
              :src="
                pluginHost +
                '/img/colors/' +
                color.metal.charAt(0).toUpperCase() +
                '.svg'
              "
              @click="setLeader(colorIdx)"
            />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "CatalogSet",
  props: ["set"],
  setup(props) {
    /**
     *
     */

    const pluginHost = process.env.VUE_APP_PLUGIN_HOST || "";

    /**
     * catalog config
     */

    const leader = ref(0);

    for (let idx in props.set.colors) {
      if (props.set.colors[idx].leader === true) {
        leader.value = idx;
        break;
      }
    }

    const currentSet = computed(() => {
      return props.set.colors[leader.value];
    });

    const setLeader = (newLeader) => {
      leader.value = newLeader;
    };

    const link = computed(() => {
      if (props.set.jewelleryType === "engagement") {
        return currentSet.value.itemNumbers.join();
      } else {
        const url =
          currentSet.value.setId +
          "_" +
          currentSet.value.metal +
          "_" +
          currentSet.value.height +
          "_" +
          currentSet.value.stoneQuality;
        return url.toLowerCase();
      }
    });

    return { currentSet, leader, setLeader, link, pluginHost };
  },
};
</script>
