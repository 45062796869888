/**
 * List of domains by countries
 *
 * @param country
 * @param withConfiguration
 */
export function domainByCountry(
  country: string,
  withConfiguration = false
): string {
  country = country.toLowerCase()

  const domains: { [key: string]: string } = {
    at: 'https://www.acredo-trauringe.at',
    // au: 'https://www.acredo.com.au',
    be: 'https://www.acredo.be',
    befr: 'https://www.acredo.be',
    benl: 'https://www.acredo-trouwringen.be',
    ch: 'https://www.acredo-trauringe.ch',
    /* cn: 'http://www.acredo.cn', */
    de: 'https://www.acredo-trauringe.de',
    dk: 'https://www.acredo.dk',
    ee: 'https://www.acredo-abielusormus.ee',
    es: 'https://www.acredo-alianzas.es',
    hk: 'https://www.acredo.com.hk',
    /* is: 'https://www.acredo.is', */
    /* lt: 'http://www.acredo-vestuviniaiziedai.lt', */
    jp: 'https://www.acredo.jp',
    kr: 'https://www.acredo.kr',
    nl: 'https://www.acredo-trouwringen.nl',
    no: 'https://www.acredo-norway.com',
    sg: 'https://www.acredo.com.sg/en',
    tw: 'https://www.acredorings.com.tw',
    us: 'https://www.acredo-bridals.com',
  }

  const configurationUrl: { [key: string]: string } = {
    at: '/konfigurator/',
    be: '/boutiques/',
    befr: '/configurateur/',
    benl: '/configurator/',
    ch: '/konfigurator/',
    cn: '/configurator/',
    de: '/konfigurator/',
    dk: '/configurator/',
    ee: '/configurator/',
    es: '/configurator/',
    hk: '/configurator/',
    is: '/',
    lt: '/',
    jp: '/configurator/',
    kr: '/configurator/',
    nl: '/configurator/',
    no: '/configurator/',
    tw: '/configurator/',
    sg: '/configurator/',
    us: '/configurator/',
  }

  if (domains[country]) {
    return domains[country] + (withConfiguration ? configurationUrl[country] : '')
  } else {
    return ''
  }
}