
import {computed, ref} from "vue";
import {DaoCatalog} from "@/assets/ts/catalog";
import {useStore} from "vuex";
import CatalogSet from "@/components/catalog/set.vue";
import CatalogNav from "@/components/catalog/nav.vue";

type catalogType = {
  catalog: string;
  items: { jewelleryType: string }[];
};

declare const window: any;

export default {
  name: "Catalog",
  components: {CatalogNav, CatalogSet},
  setup() {

    /**
     * catalog config
     */

    const itemsPerPage = 24;
    const rawCatalogSets = ref({catalog: "", items: []} as catalogType);
    const currentPage = ref(0);
    const numberOfSets = ref(0);

    const catalogSlug = ref("");
    const country = ref("de");

    /**
     * catalog init
     */

    const init = () => {
      if (typeof window.acredoCatalog !== "undefined") {
        catalogSlug.value =
            typeof window.acredoCatalog.catalog.slug === "string"
                ? window.acredoCatalog.catalog.slug
                : "weddingRings";

        country.value =
            typeof window.acredoCatalog.country === "string"
                ? window.acredoCatalog.country
                : "de";

        loadCatalog();
      }
    };

    const loadCatalog = () => {
      /**
       * Gets the items from the catalog API
       */
      const CatalogObj = new DaoCatalog();
      const store = useStore();

      store.commit("loading/actionStarted");
      CatalogObj.get(country.value, catalogSlug.value).then((data) => {
        rawCatalogSets.value = data as catalogType;
        numberOfSets.value = rawCatalogSets.value.items.length;
        store.commit("loading/actionFinished");
      });
    };

    init();

    /**
     * Extract the items that should be shown.
     */

    const catalogSets = computed(() => {
      return rawCatalogSets.value.items.slice(
          currentPage.value * itemsPerPage,
          (currentPage.value + 1) * itemsPerPage
      );
    });

    return {
      catalogSlug,
      catalogSets,
      currentPage,
      itemsPerPage,
      numberOfSets
    };
  },
};
